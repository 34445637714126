import React from "react";

const TextArea = ({ ariaLabel, name, type, placeholder, field, rows }) => (
  <textarea
    {...field}
    aria-label={ariaLabel}
    name={name}
    type={type}
    rows={rows}
    className="py-3 px-4 block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md"
    placeholder={placeholder}
  />
);

export default TextArea;