import React from 'react'
import Layout from '../../components/layouts/MainLayout'
import ContactForm from '../../components/forms/ContactForm'

const metaData = {
  title: "Contact Greenville Countertops",
  description: "Get a free estimate for your countertops, ask us a question or check granite, quartz or marble inventory."
}

const serviceAreas = [
  "Greenville",
  "Spartanburg",
  "Mauldin",
  "Simpsonville",
  "Taylors",
  "Easley",
  "Anderson",
  "Clemson",
  "Pickens",
  "Cowpens"
].sort()

const AreaListing = (props) => (
  <div>
    <h3 className="text-lg leading-6 font-medium text-gray-900">
      {props.area}
    </h3>
  </div>
)

export default function ContactIndex() {
  return (
    <Layout metaData={metaData}>
      <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-xl mx-auto">
          <svg className="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
            <defs>
              <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
          </svg>
          <svg className="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
            <defs>
              <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
          </svg>
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Contact Greenville Countertops
            </h2>
            <p className="mt-4 text-lg leading-6 text-primary-500">
              Need a free quote or just have a question about your project?
            </p>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              We are always happy to help.
            </p>
          </div>

          <ContactForm />

        </div>
      </div>


      <div className="bg-primary-50">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="divide-y-2 divide-gray-200">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                Get in touch
              </h2>
              <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Sales
                  </h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <div>
                      <dt className="sr-only">
                        Email
                      </dt>
                      <dd>
                        info@greenvillecountertops.com
                      </dd>
                    </div>
                    <div className="mt-1">
                      <dt className="sr-only">
                        Phone number
                      </dt>
                      <dd>
                        864-207-4743
                      </dd>
                    </div>
                  </dl>
                </div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Questions
                  </h3>
                  <dl className="mt-2 text-base text-gray-500">
                    <div>
                      <dt className="sr-only">
                        Email
                      </dt>
                      <dd>
                        info@greenvillecountertops.com
                      </dd>
                    </div>
                    <div className="mt-1">
                      <dt className="sr-only">
                        Phone number
                      </dt>
                      <dd>
                        864-207-4743
                      </dd>
                    </div>
                  </dl>
                </div>                
              </div>
            </div>
            <div className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                Service Areas
              </h2>
              <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">

                {serviceAreas.map( (area, index) => (
                  <AreaListing key={index} area={area} />
                ))}

              </div>
            </div>
          </div>
        </div>
      </div>


    </Layout>
  )
}
