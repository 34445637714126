import React from "react";
import { useField } from "formik";
import FormError from "./FormError";
import TextArea from "./TextArea";

const FormTextArea = ({ ariaLabel, name, type, rows, placeholder }) => {
  const [field, meta] = useField(name);
  return (
    <>
      <TextArea
        field={field}
        ariaLabel={ariaLabel}
        name={field.name}
        type={type}
        rows={rows}
        placeholder={placeholder}
      />
      {meta.touched && meta.error ? <FormError text={meta.error} /> : null}
    </>
  );
};

export default FormTextArea;