import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from 'axios';

import FormSuccess from './common/FormSuccess';
import FormError from "./common/FormError";
import FormInput from "./common/FormInput";
import FormTextArea from "./common/FormTextArea";
import Label from "./common/Label";


export default function Contact( props ) {
  const { site } = useStaticQuery(query)
  const {siteMetadata} = site
  const [submitSuccess, setSubmitSuccess] = useState();
  const [submitError, setSubmitError] = useState();
  const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;


  const InteractionSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    phone: Yup.string()
      .min(8)
      .matches(phoneRegExp, "Valid number with area code")
      .required("Phone is required"),
    zip: Yup.string()
      .min(5)
      .required("Zip Code is required")
  });

  const handleSubmit = async values => {
    
    const interaction = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      zip_code: values.zip,
      description: values.description,
      company: values.company
    };

    const options = {
      headers: {
        'Content-Type' : 'application/json;charset=utf-8',
        'Authorization' : `Bearer ${process.env.GATSBY_API_KEY}`
      }
    }

    try {

      const response = await axios.post(`${process.env.GATSBY_API_URL}/api/interaction`, interaction, options)
      if(response) {
        setSubmitSuccess('Someone from our team will contact you.');
        setSubmitError("");
      }

    } catch (error) {
      setSubmitError(`Error submitting your request. Please call ${siteMetadata.phone}`)
      setSubmitSuccess("");
    }
  };

  return (

    <div className="mt-12">

      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          zip: "",
          description: "",
          company: `${siteMetadata.company}`
        }}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
        validationSchema={InteractionSchema}
      >
        {() => (
          <Form className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">

            <div className="sm:col-span-2">
              <Label text="Name" />
              <div className="mt-1">
                <FormInput
                  ariaLabel="Name"
                  name="name"
                  type="text"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <Label text="Email" />
              <div className="mt-1">
                <FormInput
                  ariaLabel="Email address"
                  name="email"
                  type="email"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <Label text="Phone" />
              <div className="mt-1">
                <FormInput
                  ariaLabel="Phone Number"
                  name="phone"
                  type="text"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <Label text="Zip Code" />
              <div className="mt-1">
                <FormInput
                  ariaLabel="Zip Code"
                  name="zip"
                  type="text"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <Label text="Project Details or Question" />
              <div className="mt-1">
                <FormTextArea
                  ariaLabel="Description"
                  name="description"
                  type="textarea"
                  rows="4"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <button type="submit" className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ringprimary-500">
                Contact Us
              </button>
            </div>

            <div className="w-full sm:col-span-2">
              {submitSuccess && <FormSuccess text={submitSuccess} />}
              {submitError && <FormError text={submitError} />}
            </div>
            
          </Form>
        )}
      </Formik>

    </div>
    
  );
}

const query = graphql`
  query formSiteConfig {
    site {
      siteMetadata {
        company
        siteUrl
        mainContactPhone
        defaultTitle: title
        defaultDescription: description
        defaultImage
        twitterUsername
      }
    }
  }
`